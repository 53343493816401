import Mock from 'mockjs'
import menu from './menu.json'
import home from './home/index.json'
import cate from './cate.json'
import cateArticleList from './article/cateArticleList.json'
import articleDetail from './article/detail.json'
import videoIndex from './video/index.json'
import video_detail from './video/video_detail.json'
import video_comment from './video/video_comment.json'
import cate_movie_list from './video/cate_movie_list.json'
import topic_list from './topic/list.json'
import topic_comment_list from './topic/topic_comment_list.json'
import user_ask from './ask/user_ask.json'

Mock.mock('/api/index/menu', menu)
Mock.mock('/api/home/index', home)
Mock.mock('/api/cate', cate)
Mock.mock('/api/article/cateArticleList', cateArticleList)
Mock.mock('/api/article/getDetail', articleDetail)
Mock.mock('/api/video/index', videoIndex)
Mock.mock('/api/video/video_detail', video_detail)
Mock.mock('/api/video/video_comment', video_comment)
Mock.mock('/api/video/cate_movie_list', cate_movie_list)
Mock.mock('/api/topic/get_topic_list', topic_list)
Mock.mock('/api/topic/topic_comment_list', topic_comment_list)
Mock.mock('/api/ask/user_ask', user_ask)
