import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import { loginout } from '@/utils/tool'
import comm from '@/utils/comm'

let isMagg = true

// create an axios instance
const service = axios.create({
  baseURL: comm.cesuUrl,
  timeout: 15000,
})

service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers['token'] = getToken()
    }
    // headers中配置serialize为true开启序列化
    // if (config.method === 'post') {
    //   config.data = serialize(config.data)
    // }
    return config
  },
  (error) => {
    console.log(error)
    Message.error(error)
    return Promise.reject()
  }
)

service.interceptors.response.use(
  (response) => {
    const status = Number(response.data.code)
    const message = response.data.msg || '未知错误'
    // console.log(status)
    // 登录过期
    if (status === 401) {
      if (isMagg) {
        Message({
          message: message,
          type: 'error',
        })
        isMagg = false
        setTimeout(() => {
          isMagg = true
        }, 1000)
      }
      setTimeout(() => {
        loginout()
      }, 1000)
      Promise.reject(new Error(message))
    }
    if (status != 200) {
      if (isMagg) {
        Message({
          message: message,
          type: 'error',
        })
        isMagg = false
        setTimeout(() => {
          isMagg = true
        }, 1000)
      }
      Promise.reject(new Error(message))
      return response.data
    }
    return response.data
  },
  (error) => {
    console.log(error)
    const message = error.response.data.msg || '未知错误'
    if (isMagg) {
      Message({
        message: message,
        type: 'error',
      })
      isMagg = false
      setTimeout(() => {
        isMagg = true
      }, 1000)
    }
    Promise.reject()
    return error
  }
)

export default service
