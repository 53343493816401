import Vue from 'vue'
import ElementUI from 'element-ui'
ElementUI.Dialog.props.closeOnClickModal.default = false
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Share from 'vue-social-share'
import '@/libs/vue-social-share/dist/client.css'
import 'font-awesome/css/font-awesome.min.css'
import service from '@/utils/request'
import { previewFileAddress } from '@/utils/file'
require('./mock/mock.js')

Vue.use(ElementUI)
Vue.use(Share)
Vue.prototype.$axios = service
Vue.prototype.$previewFileAddress = previewFileAddress
Vue.config.productionTip = false
import VueQuillEditor from 'vue-quill-editor'
// require styles 导入富文本编辑器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './style/editor.scss'
Vue.use(VueQuillEditor, {})


new Vue({
    router,
    store,
    render: (h) => h(App),
    data: {
        eventHub: new Vue(),
    },
}).$mount('#app')
