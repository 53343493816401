import comm from '@/utils/comm'

export const previewFileAddress = (url) => {
  if (!url) {
    return ''
  }
  const isIncloudProtocol = url.split(':')[0] === 'http' || url.split(':')[0] === 'https'
  if (!isIncloudProtocol) {
    return comm.cesuUrl + url
  }
  return url
}
