import Vue from 'vue'
import Vuex from 'vuex'
import { getToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: getToken() ? true : false,
  },
  getters: {
    isLogin: (state) => state.isLogin,
  },
  mutations: {
    handleIsLogin(state, data) {
      state.isLogin = data
    },
  },
})
